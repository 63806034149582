<template>
  <div class="pg-dashboard pg-page">
    <div class="pg-header pt-5 pb-3">
      <img :src="app.koperasi_logo" alt="">
      <!-- <h1>Mobile {{app.koperasi_name}}</h1> -->
      <h6>Transaksi</h6>
      <div class="pg-header-nav-btn">
        <router-link to="/profile">
          <i class="fas fa-cog"></i>
        </router-link>
      </div>
    </div>
    <div class="pg-content">
      <div class="pg-dashboard-nav">
        <router-link to="/transaksi/kirim">
          <div><i class="fas fa-sign-out-alt"></i></div>
          <span>Kirim</span>
        </router-link>
        <router-link class="pembiayaan" to="/transaksi/terima">
          <div><i class="fas fa-sign-in-alt"></i></div>
          <span>Terima</span>
        </router-link>
        <div class="w-100"></div>
        <router-link class="tabungan" to="/transaksi/histori">
          <div><i class="fas fa-history"></i></div>
          <span>Histori</span>
        </router-link>
        <router-link class="transaksi" to="/transaksi/topup">
          <div><i class="fas fa-arrow-up"></i></div>
          <span>Top Up</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapGetters
} from "vuex";
import axios from 'axios'
import {baseUrl,settings} from '../config'
export default {
  data(){
    return {
      app : settings,
      profile : {
        branch_name:  null,
        cm_name: null,
        cif_no: null,
        name:  null,
        saldo: null,
        message: null
      },
      hitlog : {
        cif_no: null,
        timestamp : null,
        menu_akses : null
      }
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    getHitlog(){
      this.hitlog.loading = true
      let url = `${baseUrl}/histori_login`
      let payloadData = {
        cif_no : this.user.cif_no,
        timestamp : this.user.timestamp,
        menu_akses : 'Transaksi'
      }
      console.log(payloadData);

      let payload = new FormData()
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      axios
      .post(url,payload)
      .then((res)=>{
        this.hitlog.loading = false
        this.hitlog = res.data
      })
      .catch((res)=>{
        this.hitlog.loading = false
        this.notif('Error',res.message,'danger')
      })
    }
  },
  mounted(){
    this.getHitlog()
  }
}
</script>